<template>
    <div class="top-div">
        <div class="text-left">
            <div class="text-shi">{{countyUserInfo.organizationName}}</div>
            <div class="text-date">{{now | formatDateTime('yyyy年MM月d日  星期W  HH:mm:ss ')}}</div>
        </div>
        <!-- <div class="text-right">
            <img class="img-we" src="@/assets/img/county/weather.png" alt="">
            <div class="text-we">15-20℃  小雨转晴  空气质量：优</div>
        </div> -->

        <div class="text-right">

            <div @click="loginout" class="text-we hand">退出登录</div>
        </div>
    </div>
</template>
<script>
import cookie from '@/util/cookie'
export default {
    computed: {
        countyUserInfo () {
          return this.$store.state.user.userInfo
        }
     },
    data () {
        return {
            now: ''
        }
    },
    methods: {
        updateTime () {
            this.now = new Date()
            setTimeout(() => {
                this.updateTime()
            }, 1000)
        },
        loginout () {
            cookie.clearAll()
           
            // this.$router.replace('login')
             location.assign(location.origin + location.pathname)
        }
    },
    mounted () {
        this.updateTime()
    }
}
</script>
<style scoped>
.top-div{
    width: 19.2rem; height: 0.65rem; position: relative;
    background: url(../../../assets/img/county/top-bg.png);
    box-sizing: border-box; padding: 0 0.56rem;
    background-size: 100% 100%;
}
.text-left{
    color: #FEFFFF; position: absolute; bottom: 0.1rem;
}
.text-shi{
    display: inline-block; vertical-align: bottom; font-size: 0.18rem;
}
.text-date{
    display: inline-block; vertical-align: bottom; font-size: 0.14rem; margin: 0 0 0 0.1rem;
}
.text-right{
    color: #FEFFFF; position: absolute; bottom: 0.1rem; right: 0.56rem;
}
.img-we{
    display: inline-block; vertical-align: middle; margin: 0 0.13rem 0 0;
    width: 0.2rem; height: 0.18rem;
}
.text-we{
    font-size: 0.18rem; display: inline-block; vertical-align: middle;
}
.hand{
    cursor: pointer;
}
</style>
